<template>
  <div>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12" md="4">
          <base-material-card
            class="v-card-profile"
            avatar="/HRvecfiles/logo.png"
          >
            <v-card-text class="text-center">
              <div align="center">
                <img
                  v-if="users.personnel_etc_pic"
                  width="300"
                  style="border-radius: 8px"
                  :src="'/HRvecfiles/' + users.personnel_etc_pic"
                />
                <img
                  v-else
                  width="200"
                  src="/HRvecfiles/blank-human-image.png"
                  style="border-radius: 30px"
                />
              </div>
              <div class="text-center">
                <v-btn
                  @click.native="personnelPicDelete()"
                  color="red darken-3"
                  v-if="users.personnel_etc_pic"
                  dark
                  icon
                >
                  <v-icon>mdi-delete-circle-outline</v-icon>
                </v-btn>
              </div>
              <h3 class="font-weight-light mb-1 grey--text">
                {{ users.personnel_etc_id_card }}
              </h3>
              <h3 class="font-weight-light mb-1 grey--text">
                {{ users.personnel_etc_title
                }}{{ users.personnel_etc_fristname }}
                {{ users.personnel_etc_lastname }}
              </h3>
              <h4 class="font-weight-light mb-3 black--text">
                {{ users.college_name }}
              </h4>
              <p class="font-weight-light grey--text"></p>

              <div class="text-center">
                <v-btn
                  elevation="2"
                  rounded
                  color="warning"
                  class="mr-0"
                  @click.native="personnelPicUpdate()"
                >
                  <v-icon>mdi-pencil</v-icon> แก้ไขรูปโปรไฟล์</v-btn
                >
              </div>
              <div>
                <v-btn
                  elevation="2"
                  rounded
                  color="warning"
                  class="mr-0"
                  @click.native="passwordUpdate()"
                >
                  <v-icon>mdi-pencil</v-icon>
                  แก้ไขรหัสผ่านเข้าสู่ระบบ</v-btn
                >
              </div>
            </v-card-text>
          </base-material-card>
        </v-col>
        <v-col cols="12" md="8">
          <base-material-card>
            <template v-slot:heading>
              <div class="font-weight-light">
                <h2 class="h1 font-weight-light">
                  <v-icon large left>mdi-account</v-icon> ข้อมูลส่วนบุคคล
                </h2>
              </div>
            </template>

            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-alert type="info" outlined>
                    <div class="text-center">
                      <v-icon color="indigo">
                        mdi-domain
                      </v-icon>
                      สังกัด {{ users.college_name }}
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list two-line outlined>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>เลขที่ตำแหน่ง</v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            users.personnel_etc_id_position
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>ตำแหน่ง</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ users.user_status_name }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >ใบประกอบวิชาชีพครู</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ users.personnel_etc_licence }}
                          <v-btn
                            v-if="users.personnel_etc_licencefile"
                            fab
                            outlined
                            small
                            color="info"
                            @click="viewpdflicenseteach()"
                            ><v-icon>mdi-printer</v-icon></v-btn
                          >

                          <v-btn
                            v-if="users.personnel_etc_licencefile"
                            fab
                            outlined
                            small
                            color="red"
                            class="ml-2"
                            @click="deletelicenseteach()"
                            ><v-icon>mdi-delete</v-icon></v-btn
                          >
                          <v-btn
                            v-else
                            fab
                            outlined
                            small
                            color="warning"
                            class="ml-2"
                            @click="personnelUpdate()"
                            ><v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-chip
                            color="warning"
                            dark
                            v-if="!users.personnel_etc_licencefile"
                            class="font-weight-bold"
                            >ไม่ได้แนบไฟล์ใบประกอบวิชาชีพ</v-chip
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>วันออกบัตร</v-list-item-title>
                        <v-list-item-subtitle>{{
                          users.personnel_etc_onlicence
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>วันหมดอายุ</v-list-item-title>
                        <v-list-item-subtitle
                          >{{
                            users.personnel_etc_endlicence
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                          <br />
                          <div v-if="!users.Agelicence">
                            <v-chip color="warning" dark>
                              <v-icon>mdi-information-outline</v-icon>
                              <span class="font-weight-bold"> </span>
                            </v-chip>
                          </div>

                          <div v-else-if="users.Agelicence <= 60">
                            <v-chip color="red" dark>
                              <span class="font-weight-bold">
                                ในอีก : {{ users.Agelicence || "" }} วัน
                              </span>
                            </v-chip>
                          </div>
                          <div v-else-if="users.Agelicence <= 180">
                            <v-chip color="warning" dark>
                              <span class="font-weight-bold">
                                วัน ในอีก : {{ users.Agelicence || "" }}
                              </span>
                            </v-chip>
                          </div>

                          <div v-else>
                            <span class="font-weight-bold green--text">
                              ในอีก : {{ users.Agelicence || "" }} วัน
                            </span>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list two-line outlined>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>วันเดือนปี เกิด</v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            users.personnel_etc_datebrith
                              | moment("add", "543 year")
                              | moment("D MMMM YYYY")
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >วันเดือนปี เข้าทำงาน</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            users.personnel_etc_dateapp
                              | moment("add", "543 year")
                              | moment("D MMMM YYYY")
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>เบอร์โทรติดต่อ</v-list-item-title>
                        <v-list-item-subtitle>{{
                          users.personnel_etc_tel
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-clipboard-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>E-mail</v-list-item-title>
                        <v-list-item-subtitle>{{
                          users.personnel_etc_email
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <div class="text-center">
                      <v-btn
                        elevation="2"
                        rounded
                        color="warning"
                        class="mr-0"
                        @click.native="personnelUpdate()"
                      >
                        <v-icon>mdi-pencil</v-icon>
                        ปรับปรุงข้อมูล</v-btn
                      >
                    </div>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row> </v-row>

      <!-- V-model userdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="addpersonneldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลเบื้องต้น"
              class="px-5 py-3"
              elevation="2"
            >
              <div align="right">
                {{ users.personnel_etc_id_card }} {{ users.personnel_etc_title
                }}{{ users.personnel_etc_fristname }}
                {{ users.personnel_etc_lastname }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="personnelform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="users.personnel_etc_datebrith"
                            label="วันเดือนปี เกิด"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="users.personnel_etc_datebrith"
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>

                    <v-flex md6>
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="users.personnel_etc_dateapp"
                            label="วันเดือนปี เข้าทำงาน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="users.personnel_etc_dateapp"
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12>
                      <hr color="primary" />
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เบอร์โทรติดต่อ ของท่าน : "
                        v-model="users.personnel_etc_tel"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="E-mail ของท่าน :"
                        v-model="users.personnel_etc_email"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-autocomplete
                        :items="provices_sh"
                        item-text="province_name"
                        item-value="province_ID"
                        label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                        required
                        outlined
                        :rules="[v => !!v || '']"
                        v-model="users.personnel_etc_province"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex xs12>
                      <hr color="primary" />
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        outlined
                        label="ใบอนุญาตประกอบวิชาชีพครู"
                        v-model="users.personnel_etc_licence"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่ออกบัตร"
                        v-model="users.personnel_etc_onlicence"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่บัตรหมดอายุ"
                        v-model="users.personnel_etc_endlicence"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3 v-if="users.personnel_etc_licencefile">
                      <v-btn
                        v-if="users.personnel_etc_licencefile"
                        fab
                        outlined
                        small
                        color="info"
                        @click="viewpdflicenseteach()"
                        ><v-icon>mdi-printer</v-icon></v-btn
                      >

                      <v-btn
                        v-if="users.personnel_etc_licencefile"
                        fab
                        outlined
                        small
                        color="red"
                        class="ml-2"
                        @click="deletelicenseteach()"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                      ลบใบอนุญาตเดิม
                    </v-flex>
                    <v-flex md3 v-if="!users.personnel_etc_licencefile">
                      <v-file-input
                        v-model="license_file"
                        accept=".pdf"
                        name="license_file"
                        color="deep-purple accent-4"
                        counter
                        label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex xs12>
                      <hr color="primary" />
                    </v-flex>

                    <v-flex xs12>
                      <span class="red--text">
                        * สามารถลบใบอนุญาตประกอบวิชาชีพเดิม
                        เพื่อทำการแนบไฟล์ใหม่</span
                      >
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="addpersonneldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="personnelSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model passworddialog -->
      <v-layout row justify-center>
        <v-dialog v-model="passwoorddialog" persistent max-width="50%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขรหัสผ่าน"
              class="px-5 py-3"
              elevation="2"
            >
              <div align="right">
                {{ users.personnel_etc_id_card }} {{ users.personnel_etc_title
                }}{{ users.personnel_etc_fristname }}
                {{ users.personnel_etc_lastname }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="passwordform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="Password"
                        v-model="users.personnel_etc_pass"
                        type="password"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="Confirm Password"
                        v-model="users.user_confirmpassword"
                        type="password"
                        :rules="[v => v == users.personnel_etc_pass]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="passwoorddialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="success" @click="passwordSubmit()" rounded>
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicenseteachdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicenseteachdialog" max-width="60%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecLicenseTeach/' + users.personnel_etc_licencefile"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicensedrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicensedrdialog" max-width="60%">
          <v-card class="" elevation="2">
            <embed
              :src="
                '/HRvecLicenseDirector/' + users.personnel_etc_licencedr_file
              "
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Update Pic -->
      <v-layout>
        <v-dialog v-model="personnel_etc_pic_dialog" persistent max-width="50%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขรูปโปรไฟล์"
              class="px-5 py-3"
              elevation="2"
            >
            </base-material-card>
            <v-card-text>
              <v-form ref="form5" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      เลือกไฟล์ภาพของท่าน : ชนิด JPEG / JPG

                      <v-file-input
                        v-model="imageFiles"
                        accept="image/png, image/jpeg"
                        name="imageFiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .png/jpeg"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="personnel_etc_pic_dialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="editpersonnel_etcinfoSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon> &nbsp;บันทึก</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Delete Pic -->
        <v-dialog v-model="deletefiledialog" persistent max-width="50%">
          <v-card>
            <v-card-title class="red darken-3 white--text elevation-2">
              <v-icon medium dark>fa-school</v-icon>&nbsp;ลบข้อมูลไฟล์รูป
            </v-card-title>
            <v-card-text>
              <v-form ref="deletestandardtopicform2" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 class="text-xs-center red--text">
                      ยืนยันการลบไฟล์รูป
                      {{ this.users.personnel_etc_pic }}
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.native="deletefiledialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                color="red darken-3"
                @click.native="deletefileSubmit2()"
                rounded
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model confirm -->
      <v-layout>
        <v-dialog v-model="VideoDialogYoutube" persistent max-width="80%">
          <v-card>
            <div class="text-center">
              <v-btn text large rounded @click="VideoDialogYoutubeStop()"
                ><v-icon dark>mdi-close</v-icon></v-btn
              >
              <iframe
                width="100%"
                height="600px"
                src="https://www.youtube.com/embed/mI2Fzw4etE4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";

export default {
  components: { FlipCountdown },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      period_enable: "1",
      addpersonneldialog: false,
      personnel_etc_pic_dialog: false,
      VideoDialogYoutube: false,
      deletefiledialog: false,
      passwoorddialog: false,
      transference_personnels: [],
      valid: true,
      user: {},
      updateuser: {},
      personnel: {},
      periods: [],
      periodsmanage_sedi: [],
      periodsmanage_di: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      provices_sh: [],
      user_marital_status: [
        { title: "โสด", value: "single" },
        { title: "สมรส", value: "married" },
        { title: "หม้าย", value: "widow" },
        { title: "หย่า", value: "divorce" },
        { title: "แยกกันอยู่", value: "separate" }
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      modal2: false,
      menu2: false,
      headers_teach: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "tid_ref" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "personnel_etc_id_card"
        },
        { text: "ชื่อ", align: "center", value: "personnel_etc_fristname" },
        { text: "นามสกุล", align: "center", value: "personnel_etc_lastname" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        { text: "พิมพ์ แบบคำร้อง", align: "center", value: "prints1" },
        {
          text: "พิมพ์ เอกสารชุดที่ 1",
          align: "center",
          value: "print_doc_kpi"
        },
        {
          text: "พิมพ์ เอกสาร(7.1)",
          align: "center",
          value: "print_14_71_form"
        },
        { text: "พิมพ์ เอกสาร(7.2)", align: "center", value: "print_14_form" }

        /*  { text: "พิมพ์ เอกสารชุดที่ 1", align: "center", value: "prints2" } */

        /*  { text: "ความคิดเห็นผู้อำนวยการ", align: "center", value: "actions" } */
      ],
      headers_manage: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "manage_id_ref" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "manage_personnel_etc_id_card"
        },
        { text: "ชื่อ", align: "center", value: "personnel_etc_fristname" },
        { text: "นามสกุล", align: "center", value: "personnel_etc_lastname" },
        { text: "ครั้งที่", align: "center", value: "manage_time_s" },
        { text: "ปีที่", align: "center", value: "manage_year_s" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "manage_age_app_time"
        },
        { text: "วันที่ทำรายการ", align: "center", value: "manage_date_time" },
        { text: "กรณี", align: "center", value: "manage_case_move" },
        { text: "พิมพ์", align: "center", value: "prints" },
        { text: "สถานะ", align: "center", value: "manage_status_save" }
        /*  { text: "ความคิดเห็นผู้อำนวยการ", align: "center", value: "actions" } */
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      imageFiles: null,
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_sedirectors: [],
      license_file: null,
      license_filedr: null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB"
      ],
      pdflicenseteachdialog: false,
      pdflicensedrdialog: false,
      users: [],
      period_sedirectorsprocess: [],
      sedirector_apps: [],
      checksedirector_app_idrefs: [],

      headersSecDirecApp: [
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "สถานะ", align: "center", value: "sedirector_app_status" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" },
        {
          text: "เลขที่ตำแหน่งเดิม",
          align: "left",
          value: "personnel_etc_id_position"
        },
        { text: "ครั้งที่/ปี", align: "center", value: "sedirector_app_time" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "sedirector_app_agework"
        },
        {
          text: "ตำแหน่งที่ใช้สมัคร",
          align: "center",
          value: "sedirector_app_position"
        },
        /*   { text: "อายุงาน", align: "center", value: "sedirector_age_position" },
        {
          text: "อายุงานในตำแหน่ง",
          align: "center",
          value: "sedirector_age_position_work5"
        }, */
        { text: "วินัย", align: "center", value: "sedirector_app_discipline" },

        {
          text: "ใบสมัครและเอกสารประกอบฯ",
          align: "center",
          value: "sedirector_app_file"
        },
        {
          text: "ภาค ก.",
          align: "center",
          value: "sedirector_app_file_A"
        },
        {
          text: "ภาค ข.",
          align: "center",
          value: "sedirector_app_file_B"
        },
        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "sedirector_app_datetime"
        }
      ],
      sedirector_app_idcards: [],
      period_directors: [],
      period_directorsprocess: [],
      director_apps: []
    };
  },
  async mounted() {
    await this.personnelQuery();
    let result_provice;
    result_provice = await this.$http.post("province_sh.php", {
      ApiKey: this.ApiKey
    });
    this.provices_sh = result_provice.data;
  },

  methods: {
    async personnelQuery() {
      this.loading = true;
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_etc.php", {
          ApiKey: this.ApiKey,
          personnel_etc_id_card: useression.personnel_etc_id_card
        })
        .finally(() => (this.loading = false));
      this.users = result.data;
    },

    async reload() {
      location.reload();
    },
    async viewpdflicenseteach() {
      this.pdflicenseteachdialog = true;
    },
    async viewpdflicensedr() {
      this.pdflicensedrdialog = true;
    },

    async date_app_nowCheck() {
      let datecheck = String(this.users.date_app_now);
      if (datecheck.length < 5) {
        Swal.fire({
          icon: "error",
          title:
            "ให้ งานบุคลากร ดำเนินการปรับปรุงวันที่ปฏิบัติงานของท่านให้เรียบร้อย"
        });
      }
    },

    async VideoDialogYoutubeStop() {
      var videos = document.querySelectorAll("iframe, video");
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === "video") {
          video.pause();
        } else {
          var src = video.src;
          video.src = src;
        }
      });
      this.VideoDialogYoutube = false;
    },
    async VideoDialog() {
      this.VideoDialogYoutube = true;
    },

    async personnelUpdate() {
      this.addpersonneldialog = true;
    },

    async personnelPicUpdate() {
      this.personnel_etc_pic_dialog = true;
    },

    async passwordUpdate() {
      this.users.personnel_etc_pass = "";
      this.passwoorddialog = true;
    },

    async personnelPicDelete() {
      this.deletefiledialog = true;
    },

    async personnelSubmit() {
      if (this.$refs.personnelform.validate()) {
        this.users.ApiKey = this.ApiKey;
        let uploaded = null;
        if (this.license_file != null) {
          let formData = new FormData();
          let filename =
            this.users.personnel_etc_id_card +
            "" +
            this.time_stamp +
            "" +
            "teach.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_file);
          formData.append("filename", "../HRvecLicenseTeach/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.users.personnel_etc_licencefile = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }

        let result = await this.$http.post(
          "personnel_etc.update.php",
          this.users
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnelQuery();
        }
      }
    },
    async deletelicensedr() {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพผู้บริหารเดิม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseDirector/" +
              this.users.personnel_etc_licencedr_file
          });

          result = await this.$http.post("personnel_etc.update.php", {
            ApiKey: this.ApiKey,
            personnel_etc_licencedr_file: "",
            personnel_etc_id_card: this.users.personnel_etc_id_card
          });

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnelQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async deletelicenseteach() {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพครู เดิม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseTeach/" + this.users.personnel_etc_licencefile
          });

          let resultup = await this.$http.post("personnel_etc.update.php", {
            ApiKey: this.ApiKey,
            personnel_etc_licencefile: "",
            personnel_etc_id_card: this.users.personnel_etc_id_card
          });

          if (resultup.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnelQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async passwordSubmit() {
      if (this.$refs.passwordform.validate()) {
        this.users.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "personnel_etc.update.pass.php",
          this.users
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnelQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.passwoorddialog = false;
    },

    async editpersonnel_etcinfoSubmit(upload) {
      let result = "";
      let uploaded = null;
      if (this.$refs.form5.validate()) {
        if (this.imageFiles != "") {
          let formData = new FormData();
          let filename =
            this.users.personnel_etc_id_card +
            "." +
            this.time_stamp +
            "." +
            "users.jpg";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.imageFiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.users.personnel_etc_pic = "";
            this.users.personnel_etc_pic = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }

      this.users.ApiKey = this.ApiKey;
      result = await this.$http.post("personnel_etc.update.php", this.users);
      if (result.data.status == true || uploaded == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        if (!uploaded && upload) {
          Swal.fire({
            icon: "warning",
            title: "แนบไฟล์ไม่ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.personnel_etc_pic_dialog = false;
    },
    async deletefileSubmit2() {
      let result = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename: "../HRvecfiles/" + this.users.personnel_etc_pic
      });

      if (result.data.status) {
        result = await this.$http.post("personnel_etc.update.php", {
          ApiKey: this.ApiKey,
          personnel_etc_pic: "",
          personnel_etc_id_card: this.users.personnel_etc_id_card
        });

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnelQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.deletefiledialog = false;
    }
  },
  computed: {
    marital_status() {
      let marital_status = this.users.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      } else {
        marital_result = "ไม่ได้กำหนด";
      }
      return marital_result;
    },
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    },
    user_status_type() {
      let user_status = this.users.user_status;
      let result;
      if (user_status == "teacher") {
        result = "teacher";
      } else if (user_status == "director") {
        result = "manage";
      } else if (user_status == "se_director") {
        result = "manage";
      } else if (user_status == "S") {
        result = "supervisions";
      }

      return result;
    },
    dmy_app_cal() {
      let month = this.users.appoin_month;
      let day = this.users.appoin_day;
      let year = this.users.appoin_year - 543;
      let result = month + "/" + day + "/" + year;
      return result;
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },
    year_s() {
      const d = new Date();
      let year = d.getFullYear() + 543;
      return year;
    },
    time_s() {
      let time_m;
      if (this.month_now > 2) {
        time_m = 2;
      } else {
        time_m = 1;
      }
      return time_m;
    },

    date_month_cal() {
      let time_s = this.time_s;
      if (time_s == 1) {
        let dd = "31";
        let mm = "3";
        let yyyy = parseInt(this.year_s) - 543;
        let date = yyyy + "-" + mm + "-" + dd;
        return date;
      } else if (time_s == 2) {
        let dd = "30";
        let mm = "9";
        let yyyy = parseInt(this.year_s) - 543;
        let date = yyyy + "-" + mm + "-" + dd;
        return date;
      }
    },

    get_gov_Age_year() {
      let today = new Date(this.date_month_cal);
      let DOB = new Date(this.users.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    }
  }
};
</script>
<style>
v-img {
  border-radius: 8px;
}
</style>
